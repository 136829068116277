import { THUMBNAIL_DATA, THUMBNAIL_INPUT } from "../actionTypes";
import { thumbnailRef, thumbnailImgsRef } from "../../firebase";

export const getThumbnail = () => async dispatch => {
    dispatch({
        type: THUMBNAIL_DATA,
        payload: [],
        loading: true
    });

    thumbnailRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: THUMBNAIL_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: THUMBNAIL_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}

export const getThumbnailImages = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        thumbnailImgsRef.orderByChild('thumbnail_key').equalTo(params).on('value', snap => {
            if(snap.val() === null) {
                resolve([]);
                return;
            }
    
            let items = [];
            snap.forEach(child => {
                items.push({
                    _key: child.key,
                    ...child.val()
                })
            })

            resolve(items);
        });
    });
}