import { BANNER_DATA, BANNER_INPUT } from "../actionTypes";

const initState = {
    loading: true,
    data: [],
    photo: [],
    photoUrl: ''
};

const bannerReducer = (state = initState, action) => {
    switch(action.type) {
        case BANNER_DATA:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, data: items, loading: action.loading}
        case BANNER_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
        default:
            return state;
    }
}

export default bannerReducer;