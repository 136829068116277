import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Card, Form, Row, Table, Modal, ListGroup, Spinner, Carousel,
} from 'react-bootstrap';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getCanvasFirst, getCanvasSecond } from '../../redux/actions/canvasAction';
import { getWorkshop } from '../../redux/actions/workshopAction';
import { getAvailableTiming, getAvailableDate, saveBooking } from '../../redux/actions/bookingAction';
import { NavHeader } from '../../components/header';
import { isMobile, isSafari } from 'react-device-detect';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Link, Element } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import { getDiyImg, getWorkshopImg } from '../../redux/actions/studioAction';
import axios from 'axios';
import Calendar from 'react-calendar';

let api_url = "https://liftksa-service.onrender.com/";

export default function Studio() {
  return (
    <>
        <NavHeader />
        <div style={{marginBottom: 20}}>
          <center>
            <img src={require('../../assets/images/liftStudio.png')} style={{width: isMobile ? 300 : 400, objectFit: 'contain'}} />
            <div className='content-title' style={{width: isMobile ? '100%' : '75%'}}>
              <p style={{fontFamily: 'Exo2Medium', fontSize: 20, lineHeight: 2}}>
              Welcome to The Studio, where people unwind from daily hassles, engage in fun activities with like-minded people, feed their creative curiosity, discover new things, and invest in their self-improvement.
              </p>
            </div>
          </center>
          <br/>
          <center>
           <div className='lineBreakLong'/>
          </center>
        </div>
        <DiySession />
        <WorkShop />
        <Services />
        <Facilities />

        <Element id="diyElement">
          <DiySessionForm />
        </Element>
        
        <Element id="workshopElement">
          <BookWorkShop />
        </Element>
        
        <Footer />
    </>
  );
}

const DiySession = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.studio);

  useEffect(() => {
    dispatch(getDiyImg());
  }, []);

  return(
    <Container style={{width: isMobile ? '100%' : '80%', marginTop: 50, marginBottom: isMobile ? 50 : 200}}>
      <Row>
        <Col md="6" xs="12">
          <Fade left>
            <img src={state.diyImg} style={{width: '100%'}}/>
          </Fade>
        </Col>
        <Col md="6" xs="12">
          <Fade right>
            <div className='diySessionContainer' style={{height: isMobile ? 'auto' : '90%'}}>
              <div className='titleRelative'>
                <span style={{fontFamily: 'Exo2Medium', fontSize: 16, marginLeft: 4, position: 'absolute', top: 30}}>Book your</span> <br/>
                <span style={{fontFamily: 'Exo2Medium', fontSize: 60, letterSpacing: isMobile ? 5 : 10, marginBottom: 30, color: '#6F1C4F'}}>DIY Sessions</span>
                <br/><br/>
                <p style={{fontFamily: 'Exo2Regular', fontSize: 16, letterSpacing: 5, textAlign: 'right'}}>
                  Is your inner DIY itching for a fun new project without having to buy all your own supplies and make a mess at your house? Book your session now at Lift Studio
                </p>
                <br/>
                <Link smooth={true} duration={250} to="diyElement" style={{cursor: 'pointer'}}>
                  <span style={{fontFamily: 'Courier', textDecoration: 'underline', float: 'right'}}>
                    <Button
                      style={{
                        backgroundColor: '#6F1C4F', 
                        borderRadius: 0, 
                        borderWidth: 0,
                      }}>
                        Book
                    </Button>
                  </span>
                </Link>
              </div>
            </div>
          </Fade>
        </Col>
      </Row>
    </Container>
  )
}

const WorkShop = (props) => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.studio);

  useEffect(() => {
    dispatch(getWorkshopImg());
  }, []);

  return(
    <Container style={{width: isMobile ? '100%' : '80%', marginTop: 50, marginBottom: isMobile ? 50 : 200}}>
      {isMobile ? 
        <Row style={{marginBottom: 50}}>
          <Col md="6">
            <Fade left>
              <img src={state.workshopImg} style={{width: '100%'}}/>
            </Fade>
          </Col>
          <Col md="6">
            <Fade right>
            <div className='diySessionContainer' style={{height: 'auto'}}>
              <div className='titleRelative'>
                <span style={{fontFamily: 'Exo2Medium', fontSize: 16, position: 'absolute', top: 30}}>Book your</span> <br/>
                <span style={{fontFamily: 'Exo2Medium', fontSize: 60, letterSpacing: 10, marginBottom: 30, color: '#6F1C4F'}}>Workshop</span>
                <br/><br/>
                <p style={{fontFamily: 'Exo2Regular', fontSize: 16, letterSpacing: 5, textAlign: 'right'}}>
                  Lift oers a variety of subject-based, themed and/or branded workshops for adults & kids. Stay updated and check our weekly workshops
                </p>
                <br/>
                <Link smooth={true} duration={250} to="workshopElement" style={{cursor: 'pointer'}}>
                  <span style={{fontFamily: 'Courier', textDecoration: 'underline', float: 'right'}}>
                    <Button
                      style={{
                        backgroundColor: '#6F1C4F', 
                        borderRadius: 0, 
                        borderWidth: 0,
                      }}>
                        Book
                    </Button>
                  </span>
                </Link>
              </div>
            </div>
            </Fade>
          </Col>
        </Row> : 
        <Row>
          <Col md="6">
            <Fade left>
              <div className='diySessionContainer'>
                <div className='titleRelative'>
                  <span style={{fontFamily: 'Exo2Medium', fontSize: 16, position: 'absolute', top: 30}}>Book your</span> <br/>
                  <span style={{fontFamily: 'Exo2Medium', fontSize: 60, letterSpacing: 10, marginBottom: 30, color: '#6F1C4F'}}>Workshop</span>
                  <br/><br/>
                  <p style={{fontFamily: 'Exo2Regular', fontSize: 16, letterSpacing: 5, textAlign: 'right'}}>
                    Lift offers a variety of subject-based, themed and/or branded workshops for adults & kids. Stay updated and check our weekly workshops
                  </p>
                  <br/>
                  <Link smooth={true} duration={250} to="workshopElement" style={{cursor: 'pointer'}}>
                    <span style={{fontFamily: 'Courier', textDecoration: 'underline', float: 'right'}}>
                      <Button
                        style={{
                          backgroundColor: '#6F1C4F', 
                          borderRadius: 0, 
                          borderWidth: 0,
                        }}>
                          Book
                      </Button>
                    </span>
                  </Link>
                </div>
              </div>
            </Fade>
          </Col>
          <Col md="6">
            <Fade right>
              <img src={state.workshopImg} style={{width: '100%'}}/>
            </Fade>
          </Col>
        </Row>
      }
    </Container>
  )
}

const Services = () => {
  return (
    <div>
      <div style={{backgroundColor: '#6F1C4F', padding: 5, textAlign: 'right', marginBottom: isMobile ? 50 : 100}}>
        <Fade>
        <h1 style={{color: '#fff', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 50, letterSpacing: isMobile ? 5 : 20}}>OUR SERVICES</h1>
        </Fade>
      </div>
      <Container>
        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade right>
            <ServicesTxt 
              numbering="01"
              title="One-time Workshops"
              desc="Subject-based, themed and/or branded workshops, for adults & kids"
              />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade right>
            <ServicesTxt 
              numbering="02"
              title="Long Term Workshops"
              desc="Results-oriented (ex: portfolio building), self-discovery, growth, and inspirational-based themes, for adults & kids"
              />
            </Fade>
          </Col>
          <Col />
        </Row>

        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade right>
            <ServicesTxt 
              numbering="03"
              title="Masterclass"
              desc="Artists, art history, art elds and theory, for adults only"
              />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
            <ServicesTxt 
              numbering="04"
              title="Retail"
              desc="Gift vouchers, art boxes, limited editions items, for adults & kids"
              />
            </Fade>
          </Col>
          <Col />
        </Row>

        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
            <ServicesTxt 
              numbering="05"
              title="Retreats & Getaways"
              desc="1-3 day programs, with various activities around different locations in KSA (and later international), for individual and corporation use"
              />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
            <ServicesTxt 
              numbering="06"
              title="Bespoke Events"
              desc="Private events, organizations, corporate team building, space rent, for individual and corporation use"
              />
            </Fade>
          </Col>
          <Col />
        </Row>
      </Container>
    </div>
  )
}

const ServicesTxt = (props) => {
  return (
    <div style={{marginBottom: isMobile ? 50 : 100}}>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.numbering}</h3>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.title}</h3>
      <p style={{fontFamily: 'Exo2Regular', fontSize: 20}}>{props.desc}</p>
    </div>
  )
}

const Facilities = () => {
  return (
    <Container style={{marginBottom: 100}}>
      <Fade left>
        <h1 style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 70, letterSpacing: isMobile ? 5 : 10, marginBottom: 20, color: '#6F1C4F'}}>OUR FACILITIES</h1>
      </Fade>
      {isMobile ? 
        <Fade bottom>
        <div className='studioFacilityGif'>
          <img src={require('../../assets/images/video.gif')} style={{width: '100%', height: 300}} /> 
          <div className='studioFacilityTxtMobile'>
            <Carousel indicators={false}>
              <Carousel.Item>
                <strong style={{fontSize: 15, fontFamily: 'Exo2Bold'}}>Multi-purpose workshop rooms</strong>
                <p style={{fontFamily: 'Exo2Regular', fontSize: 12}}>Small, medium, large for various group sizes</p>
              </Carousel.Item>
              <Carousel.Item>
                <strong style={{fontSize: 15, fontFamily: 'Exo2Bold'}}>Indoor - Outdoor areas</strong>
                <p></p>
              </Carousel.Item>
              <Carousel.Item>
                <strong style={{fontSize: 15, fontFamily: 'Exo2Bold'}}>Reception Area</strong>
                <p></p>
              </Carousel.Item>
              <Carousel.Item>
                <strong style={{fontSize: 15, fontFamily: 'Exo2Bold'}}>Lounge Area</strong>
                <p></p>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        </Fade>
        : 
        <Fade bottom>
        <div className='studioFacilityContainer'>
          <div className='studioFacilityBorder'></div>
          <div className='studioFacilityGif'>
            <img src={require('../../assets/images/video.gif')} style={{width: '98%', height: 650}} />
            {/* <video style={{width: '98%', height: 650, objectFit: 'cover'}} loop autoPlay>
                <source src={require('../../assets/images/video.mp4')} type="video/mp4"/>
            </video> */}
            <div className='studioFacilityTxt'>
              <Carousel indicators={false}>
                <Carousel.Item>
                  <strong style={{fontSize: 30, fontFamily: 'Exo2Bold'}}>Multi-purpose workshop rooms</strong>
                  <p style={{fontFamily: 'Exo2Regular', fontSize: 20}}>Small, medium, large for various group sizes</p>
                </Carousel.Item>
                <Carousel.Item>
                  <strong style={{fontSize: 30, fontFamily: 'Exo2Bold'}}>Indoor - Outdoor areas</strong>
                  <p></p>
                </Carousel.Item>
                <Carousel.Item>
                  <strong style={{fontSize: 30, fontFamily: 'Exo2Bold'}}>Reception Area</strong>
                  <p></p>
                </Carousel.Item>
                <Carousel.Item>
                  <strong style={{fontSize: 30, fontFamily: 'Exo2Bold'}}>Lounge Area</strong>
                  <p></p>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
        </Fade>
      }
    </Container>
  )
}

const DiySessionForm = (props) => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.canvas);
  const [dates, setDates] = useState([]);
  const [timings, setTimings] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNUmber] = useState('');
  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  const [qty, setQty] = useState('1');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectHours, setSelectHours] = useState(null);
  
  useEffect(() => {
    dispatch(getCanvasFirst());
    dispatch(getCanvasSecond());
  }, []);

  function _selectedItem(item) {
    setSelectHours(null);
    setTimings([]);
    setSelectedData(item);
    dispatch(getAvailableDate('DIY', item._key)).then(res => {
      setDates(res);
    });
  }

  function _selectDate(data) {
    setTimings([]);
    setSelectedDate(data);
  }

  function _selectHours(value) {
    setSelectHours(value);
    setTimings([]);
    dispatch(getAvailableTiming(selectedDate, 'DIY')).then(res => {
      if(res !== undefined) {
        if(res.length > 0) {
          for(var i=0; i<res.length; i++) {
            let fromTime = moment(res[i].from, 'HH:mm a');
            let toTime = moment(res[i].to, 'HH:mm a').add(1, "hour");
            let duration = moment.duration(toTime.diff(fromTime));
            let diff = duration.hours();
            let array = [];

            for (var j = 0; diff > j; j++) {
              let from = moment(fromTime).add(j, 'hours').format('hh:mm a');
              let to = moment(from, 'HH:mm a').add(value, 'hours').format('hh:mm a');

              if(to < toTime.format('hh:mm a')) {
                array.push(from+' - '+to);
              }
            }

            setTimings(array);
          }
        }else {
          setTimings([]);
        }
      }
    })
  }

  function _total() {
    let priceQty = qty === "" ? 0 : qty;
    return selectedData !== null ? parseFloat(selectedData.price) * parseInt(priceQty) : "0";
  }

  return (
    <Container style={{marginBottom: 100}}>
      <div style={{textAlign: 'right'}}>
        <Fade>
        <h1 style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 25 : 50, letterSpacing: isMobile ? 5 : 20, color: '#6F1C4F'}}>DIY Sessions</h1>
        </Fade>
      </div>
      <div style={{width: '100%', height: 2, backgroundColor: '#000', marginBottom: isMobile ? 50 : 100}}/>
      
      <div style={{marginBottom: 20}}>
        <Fade left>
        <strong style={{fontFamily: 'Exo2Medium', letterSpacing: 2, fontSize: 20}}>1. Choose your Canvas</strong> <br/><br/>
        </Fade>
        <Fade left>
        <div style={{backgroundColor: '#C6A8BD', padding: 5, marginBottom: 30}}>
          <span style={{fontFamily: 'Exo2Bold'}}>For 1-2 hours</span>
        </div>
        </Fade>
        <Row style={{marginBottom: 30}}>
        {state.first.map((item, k) => {
          return (
            <Col md="3" xs="12" key={k} style={{
              backgroundColor: selectedData !== null && selectedData._key === item._key ? 'rgba(198, 168, 189, 0.4)' : 'transparent', 
              paddingBottom: 10
            }}>
              <Fade right>
                <div className='diyShadow'>
                  {item.width+'x'+item.height} {item.isForAdult == undefined || item.isForAdult == true ? "" : "(Kids Only)"}
                </div>
                <br/>
                <strong style={{fontFamily: 'Exo2Bold'}}>{item.price+' '+item.currency}</strong>
                <p style={{fontFamily: 'Exo2Regular'}}>Price inclusive with VAT</p>
                <div style={{width: '100%', height: 1, backgroundColor: '#000', marginBottom: 10}} />

                <Link smooth={true} duration={250} to="numberAttendeeElement" style={{cursor: 'pointer'}}>
                  <span style={{fontFamily: 'Exo2Bold', textDecoration: 'underline', float: 'right'}}>
                    <Button
                      onClick={() => _selectedItem(item)}
                      style={{
                        backgroundColor: '#6F1C4F', 
                        borderRadius: 0, 
                        borderWidth: 0
                      }}>
                        Select
                    </Button>
                  </span>
                </Link>
              </Fade>
            </Col>
          )
        })}
        </Row>
        <Fade left>
        <div style={{backgroundColor: '#C6A8BD', padding: 5, marginBottom: 30}}>
          <span style={{fontFamily: 'Exo2Bold'}}>For 3-4 hours</span>
        </div>
        </Fade>
        <Row style={{marginBottom: 30}}>
        {state.second.map((item, k) => {
          return (
            <Col md="3" xs="12" key={k} style={{
              backgroundColor: selectedData !== null && selectedData._key === item._key ? 'rgba(198, 168, 189, 0.4)' : 'transparent', 
              paddingBottom: 10
            }}>
              <Fade right>
                <div className='diyShadow'>
                  {item.width+'x'+item.height}
                </div>
                <br/>
                <strong style={{fontFamily: 'Exo2Bold'}}>{item.price+' '+item.currency}</strong>
                <p style={{fontFamily: 'Exo2Regular'}}>Price inclusive with VAT</p>
                <div style={{width: '100%', height: 1, backgroundColor: '#000', marginBottom: 10}} />
                <Link smooth={true} duration={250} to="numberAttendeeElement" style={{cursor: 'pointer'}}>
                  <span style={{fontFamily: 'Exo2Bold', textDecoration: 'underline', float: 'right'}}>
                    <Button
                      onClick={() => _selectedItem(item)}
                      style={{
                        backgroundColor: '#6F1C4F', 
                        borderRadius: 0, 
                        borderWidth: 0
                      }}>
                        Select
                    </Button>
                  </span>
                </Link>
              </Fade>
            </Col>
          )
        })}
        </Row>
      </div>
      
      <Element id="numberAttendeeElement">
        <div style={{height: 80}}/>
      </Element>

      <div style={{marginBottom: 50}}>
        <Fade right>
          <strong style={{fontFamily: 'Exo2Medium', letterSpacing: 2, fontSize: 20}}>2. Choose your Date: </strong> <br/><br/>
        </Fade>
        <Calendar
          calendarType="US"
          minDate={new Date()}
          onChange={(val) => {
            _selectDate(val);
          }} 
          value={new Date()} 
          className="text-black"
          tileClassName="text-black20"
          />
      </div>
      
      <div style={{marginBottom: 50}}>
        <Fade right>
          <strong style={{fontFamily: 'Exo2Medium', letterSpacing: 2, fontSize: 20}}>3. Available Timings: </strong> <br/><br/>
        </Fade>
        {selectedData != null?
          selectedData.howManyHours === "1-2 Hours" ?
            <div style={{marginBottom: 40, width: 200}}>
              <Form.Select aria-label="Default select example" onChange={(e) => _selectHours(e.target.value)}>
                <option value="">Select Hours</option>
                <option value={1}>1 Hour</option>
                <option value={2}>2 Hours</option>
              </Form.Select>
            </div> : 
            <div style={{marginBottom: 40, width: 200}}>
              <Form.Select aria-label="Default select example" onChange={(e) => _selectHours(e.target.value)}>
                <option value="">Select Hours</option>
                <option value={3}>3 Hours</option>
                <option value={4}>4 Hours</option>
              </Form.Select>
            </div> :
          null
        }
        <Row>
          {selectHours !== null && timings.length > 0 ? timings.map((item, k) => {
            return (
              <Col key={k} md="3" xs="12">
                <Fade right>
                  <div 
                    style={{
                      backgroundColor: selectedTime === item ? '#6F1C4F' : '', cursor: 'pointer',
                      color: selectedTime === item ? '#fff' : '#000',
                    }} 
                    className="timingContainer"
                    onClick={() => setSelectedTime(item)}
                    >
                    {item}
                  </div>
                </Fade>
              </Col>
            )
          }) : <p>No available time</p>}
        </Row>
      </div>

      {selectedTime !== null && (
        <>
          <Fade right>
            <Row style={{marginTop: 50, marginBottom: 50}}>
              <Col md="6" xs="12">
                <Form.Group>
                  <Form.Label className='formFontFamilty'>First Name</Form.Label>
                  <Form.Control 
                    className='diyInput'
                    type='text'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <Form.Group>
                  <Form.Label className='formFontFamilty'>Last Name</Form.Label>
                  <Form.Control 
                    className='diyInput'
                    type='text'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <Form.Group>
                  <Form.Label className='formFontFamilty'>Email</Form.Label>
                  <Form.Control 
                    className='diyInput'
                    type='text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <br/>
                <Form.Group>
                  <Form.Label className='formFontFamilty'>Phone Number</Form.Label>
                  <Form.Control 
                    className='diyInput'
                    type='text'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNUmber(e.target.value)}
                    />
                </Form.Group>
              </Col>
              <Col md="6" xs="12"/>
            </Row>
          </Fade>
          
          <Fade right>
            <h1 style={{color: '#000', fontFamily: 'Exo2Medium', fontSize: 40}}>Summary</h1>
          </Fade>
          <div className='summaryContainer'>
            <div style={{backgroundColor: '#ecf0f1', padding: 20}}>
              {isMobile ? 
                <Fade bottom>
                  <Table>
                    <tr>
                      <td><strong>Hours</strong></td>
                      <td>{selectedData !== null && selectedData.howManyHours}</td>
                    </tr>
                    <tr>
                      <td><strong>Qty</strong></td>
                      <td>
                        <Form.Control style={{width: 50}} type="number" value={qty} onChange={(e) => setQty(e.target.value)}/>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Size</strong></td>
                      <td>{selectedData !== null && selectedData.width+'x'+selectedData.height}</td>
                    </tr>
                    {/* <tr>
                      <td><strong>Attendees</strong></td>
                      <td>{numberOfAttendees}</td>
                    </tr> */}
                    <tr>
                      <td><strong>Date</strong></td>
                      <td>{selectedDate !== "" && moment(selectedDate, 'YYYY-MM-DD').format('MMM Do YYYY')}</td>
                    </tr>
                    <tr>
                      <td><strong>Time</strong></td>
                      <td>{selectedTime}</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td align='right'>
                        <strong style={{fontSize: 20}}>{parseFloat(_total()).toFixed(2)} SR</strong>
                        <br/>
                        <strong style={{fontSize: 11, fontFamily: 'Exo2Regular'}}>Price inclusive with VAT</strong>
                      </td>
                    </tr>
                  </Table>
                  <Button
                    onClick={() => _payNow()}
                    style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, letterSpacing: 5, float: 'right'}}>Pay Now</Button>
                </Fade> : 
                <Fade bottom>
                  <Table>
                    <thead style={{fontFamily: 'Exo2Bold'}}>
                      <tr>
                        <th><strong>Hours</strong></th>
                        <th><strong>Qty</strong></th>
                        <th><strong>Size</strong></th>
                        {/* <th><strong>Attendees</strong></th> */}
                        <th><strong>Date</strong></th>
                        <th><strong>Time</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{fontFamily: 'Exo2Medium'}}>
                        <td>{selectedData !== null && selectedData.howManyHours}</td>
                        <td>
                          <Form.Control style={{width: 50}} type="number" value={qty} onChange={(e) => setQty(e.target.value)}/>
                        </td>
                        <td>{selectedData !== null && selectedData.width+'x'+selectedData.height}</td>
                        {/* <td>{numberOfAttendees}</td> */}
                        <td>{selectedDate !== "" && moment(selectedDate, 'YYYY-MM-DD').format('MMM Do YYYY')}</td>
                        <td>{selectedTime}</td>
                      </tr>
                      <tr style={{fontFamily: 'Exo2Bold'}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td align='right'>
                          <strong style={{fontSize: 20}}>{parseFloat(_total()).toFixed(2)} SR</strong>
                          <div style={{width: '100%', height: 1, backgroundColor: '#000'}} />
                          <strong style={{fontSize: 11, fontFamily: 'Exo2Regular'}}>Price inclusive with VAT</strong>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  
                  <Button 
                    onClick={() => _payNow()}
                    style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, float: 'right', letterSpacing: 5}}>Pay Now</Button>
                </Fade>
              }
            </div>
          </div>
        </>
      )}
      
      <Modal show={paymentModal} onHide={() => setPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontFamily: 'Exo2Bold'}}>Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ListGroup variant="flush" style={{fontFamily: 'Exo2Regular'}}>
            <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => _pay('Mada')}>Mada</ListGroup.Item>
            {isSafari ? <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => _pay('Apple Pay')}>Apple Pay</ListGroup.Item> : null}
            <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => _pay('Visa Master')}>Visa Master</ListGroup.Item>
          </ListGroup> */}
          <Form.Group>
            <Form.Select 
              className='diyInput'
              type='select'
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              >
              <option value="">Please Select</option>
              <option value="MADA">Mada</option>
              {/* {isSafari ? <option value="Apple Pay">Apple Pay</option> : null } */}
              <option value="VISA">Visa</option>
              <option value="MASTER">Master Card</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {paymentLoading ? 
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner> : 
              paymentMethod !== "" ? <Button 
                onClick={() => _pay()}
                style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}>
                  Continue
              </Button> : null
          }
        </Modal.Footer>
        <img src={require('../../assets/images/card2.png')} style={{width: '100%', height: 40, objectFit: 'contain'}}/>
        <br/>
      </Modal>
    </Container>
  )

  function _payNow() {
    if(selectedData === null) {
      toast('Please select canvas size')
      return false;
    }
    // if(numberOfAttendees === "") {
    //   toast('Please enter number of attendees');
    //   return false;
    // }
    if(selectedDate === "") {
      toast('Please select your date');
      return false;
    }
    if(selectedTime === null) {
      toast('Please select your timing');
      return false;
    }
    if(firstName === "") {
      toast('Please enter your first name');
      return false;
    }
    if(lastName === "") {
      toast('Please enter your last name');
      return false;
    }
    if(email === "") {
      toast('Please enter your email');
      return false;
    }
    if(phoneNumber === "") {
      toast('Please enter your phone number');
      return false;
    }

    setPaymentModal(true);
  }

  function _pay() {
    setPaymentLoading(true);
    switch(paymentMethod) {
      case 'MADA':
        _paymentMADA(paymentMethod);
        break;
      case 'VISA':
      case 'MASTER':
        _paymentCC(paymentMethod);
        break;
      case 'Apple Pay':
        _paymentApplePay(paymentMethod);
        break;
    }
  }

  function _paymentCC(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var vatCharge = parseFloat(selectedData.price) * vat;
    var subtotal = parseFloat(selectedData.price) * parseInt(qty);
    var grandTotal = subtotal + vatCharge;

    axios.get(api_url+'payment-cc',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `DIY (CREDIT/DEBIT)`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "DIY",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/cc/${paymentMethod}`;
        }
      });
    }).catch(error => {
      //setPaymentLoading(false);
      alert(error);
    });
  }

  function _paymentMADA(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var vatCharge = parseFloat(selectedData.price) * vat;
    var subtotal = parseFloat(selectedData.price) * parseInt(qty);
    var grandTotal = subtotal + vatCharge;

    axios.get(api_url+'payment-mada',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `DIY (MADA)`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "DIY",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/mada/${paymentMethod}`;
        }
      });
    }).catch(error => {
      //setPaymentLoading(false);
      alert(error);
    });
  }

  function _paymentApplePay(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var vatCharge = parseFloat(selectedData.price) * vat;
    var subtotal = parseFloat(selectedData.price) * parseInt(qty);
    var grandTotal = subtotal + vatCharge;

    axios.get(api_url+'payment-apple',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `DIY (APPLE PAY) - ${selectedData.title}`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "DIY",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/apple_pay`;
        }
      });
    }).catch(error => {
      //setPaymentLoading(false);
      alert(error);
    });
  }
}

const BookWorkShop = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.workshop);
  const [dates, setDates] = useState([]);
  const [timings, setTimings] = useState([]);

  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNUmber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [qty, setQty] = useState('1');
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    dispatch(getWorkshop());
  }, []);

  function _selectedItem(item) {
    setSelectedData(item);
    setDetailsModal(true);
    dispatch(getAvailableDate('Workshop', item._key)).then(res => {
      setDates(res);
    });
  }

  function _selectDate(data) {
    setSelectedDate(data)
    setTimings([]);
    dispatch(getAvailableTiming(data, 'Workshop')).then(res => {
      if(res !== undefined) {
        if(res.length > 0) {
          for(var i=0; i<res.length; i++) {
            let fromTime = moment(res[i].from, 'HH:mm a');
            let toTime = moment(res[i].to, 'HH:mm a');
            let duration = moment.duration(toTime.diff(fromTime));
            let diff = duration.hours();
            let array = [];
  
            for (i = 0; diff > i; i++) {
              let result = moment(fromTime).add(i, 'hours').format('hh:mm a');
              //let lastElement = res[res.length - 1];
              //let lastTime = moment(lastElement.to, 'HH:mm a');

              // if(moment(lastTime).format('hh:mm a') === moment(toTime).format('hh:mm a')) {
              //   array.push(moment(toTime).format('hh:mm a'));
              // }else  {
              //   array.push(result);
              // }
              array.push(result);
            }

            let newArr = [...array, moment(toTime).format('hh:mm a')];
            setTimings(newArr);
          }
        }else {
          setTimings([]);
        }
      }
    })
  }

  return (
    <Container style={{marginBottom: 100}}>
      <center>
        <Fade>
        <h1 style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 20 : 50, letterSpacing: isMobile ? 5 : 20, color: '#6F1C4F'}}>
          BOOK YOUR WORKSHOP
        </h1>
        </Fade>
      </center>
      <br/><br/>

      {isMobile ? state.data.map((item, k) => {
          return(
            <Card key={k}>
              <Fade bottom>
              <Card.Img variant="top" src={item.background} />
              <Card.Body>
                <Card.Title style={{fontFamily: 'Exo2Regular'}}>{item.title}</Card.Title>
                <Card.Text style={{fontFamily: 'Exo2Regular'}}>{item.description}</Card.Text>
                <hr/>
                <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Age Group: {item.ageGroupFrom+' - '+item.ageGroupTo}</span> <br/>
                <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Duration: {item.duration+' hour(s)'}</span> <br/>
                <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Instructor: {item.instructor}</span> <br/>
                <br/>
                <strong style={{fontFamily: 'Exo2Bold', color: '#000'}}>{item.price+' '+item.currency}</strong>
                <p style={{fontFamily: 'Exo2Regular', color: '#000'}}>Price inclusive with VAT</p>
                <div style={{width: '100%', height: 1, backgroundColor: '#000', marginBottom: 10}} />
                <p 
                  onClick={() => {
                    setSelectedData(item);
                    setDetailsModal(true);
                  }}
                  style={{fontFamily: 'Exo2Bold', textAlign: 'right', cursor: 'pointer', color: '#000'}}>Select</p>
              </Card.Body>
              </Fade>
            </Card>
          )
        }) : 
        state.data.map((item, k) => {
          return(
            <div key={k} className="workShopListItems">
              <Fade bottom>
              <img src={item.background} style={{width: '100%', height: 400, objectFit: 'cover'}}/>
              <div className='workShopListItemsContent'>
                <img src={item.thumbnail} style={{width: 360, height: 360, objectFit: 'cover'}}/>
                <div style={{marginLeft: 20}}>
                  <h3 style={{fontFamily: 'Exo2Bold', color: '#fff'}}>{item.title}</h3>
                  <p style={{fontFamily: 'Exo2Regular', color: '#fff'}}>{item.description}</p>
                  <br/>
                  <Row>
                    <Col md="6" xs="12">
                      <span style={{fontFamily: 'Exo2Regular', color: '#fff'}}>Age Group: {item.ageGroupFrom+' - '+item.ageGroupTo}</span> <br/>
                      <span style={{fontFamily: 'Exo2Regular', color: '#fff'}}>Duration: {item.duration+' hour(s)'}</span> <br/>
                      <span style={{fontFamily: 'Exo2Regular', color: '#fff'}}>Instructor: {item.instructor}</span> <br/>
                    </Col>
                    <Col md="6" xs="12">
                      <strong style={{fontFamily: 'Exo2Bold', color: '#fff'}}>{item.price+' '+item.currency}</strong>
                      <p style={{fontFamily: 'Exo2Regular', color: '#fff'}}>Price inclusive with VAT</p>
                      <div style={{width: '100%', height: 1, backgroundColor: '#fff', marginBottom: 10}} />
                      <p
                        onClick={() => _selectedItem(item)} 
                        style={{fontFamily: 'Exo2Bold', textAlign: 'right', cursor: 'pointer', color: '#fff'}}>Select</p>
                    </Col>
                  </Row>
                </div>
              </div>
              </Fade>
            </div>
          )
        })
      }
      
      {selectedData !== null && (
        <Modal size="lg" show={detailsModal} onHide={() => setDetailsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Exo2Bold'}}>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="workShopListItems">
            <img src={selectedData.background} style={{width: '100%', height: 400, objectFit: 'cover'}}/>
            <div className='workShopListItemsContent'>
              <img src={selectedData.thumbnail} style={{width: 360, height: 400, objectFit: 'cover'}}/>
            </div>
            </div>
            <br/><br/>
            <h3 style={{fontFamily: 'Exo2Bold', color: '#000'}}>{selectedData.title}</h3>
            <p style={{fontFamily: 'Exo2Regular', color: '#000'}}>{selectedData.description}</p>
            <hr />
            <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Age Group: {selectedData.ageGroupFrom+' - '+selectedData.ageGroupTo}</span> <br/>
            <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Duration: {selectedData.duration+' hour(s)'}</span> <br/>
            <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>Instructor: {selectedData.instructor}</span> <br/>
            <hr />
            <strong style={{fontFamily: 'Exo2Bold', color: '#000'}}>{selectedData.price+' '+selectedData.currency}</strong>
            <p style={{fontFamily: 'Exo2Regular', color: '#000'}}>Price inclusive with VAT</p> <br/>
            <div style={{marginTop: 30}}>
              <Form.Label className='formFontFamilty'>Select Date</Form.Label>
              <Calendar
                calendarType="US"
                minDate={new Date()}
                onChange={(val) => {
                  _selectDate(val);
                }} 
                value={new Date()} 
                className="text-black"
                tileClassName="text-black20"
                />

              <>
                <br/>
                <Form.Label className='formFontFamilty'>Select Time</Form.Label>
                <Row>
                  {timings.length > 0 ? timings.map((item, k) => {
                    return (
                      <Col key={k} md="3" xs="12">
                        <Fade right>
                        <div 
                          style={{
                            backgroundColor: selectedTime === item ? '#6F1C4F' : '', cursor: 'pointer',
                            color: selectedTime  === item ? '#fff' : '#000',
                          }} 
                          className="timingContainer"
                          onClick={() => setSelectedTime(item)}
                          >
                          {item}
                        </div>
                        </Fade>
                      </Col>
                    )
                  }) : <p>No available time</p>}
                </Row>
              </>

              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>First Name</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Last Name</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Email</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Phone Number</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNUmber(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Payment Method</Form.Label>
                <Form.Select 
                  className='diyInput'
                  type='select'
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                  <option value="">Please Select</option>
                  <option value="MADA">Mada</option>
                  {/* {isSafari ? <option value="Apple Pay">Apple Pay</option> : null } */}
                  <option value="VISA">Visa</option>
                  <option value="MASTER">Master Card</option>
                </Form.Select>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {paymentLoading ? 
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner> : 
              paymentMethod !== "" ? <Button 
                  onClick={() => _payNow()}
                  style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}>
                    Continue
                </Button> : null
            }
            
          </Modal.Footer>
          <img src={require('../../assets/images/card2.png')} style={{width: '100%', height: 40, objectFit: 'contain'}}/>
          <br/>
        </Modal>
      )}
    </Container>
  )

  function _payNow() {
    if(selectedDate === "") {
      toast('Please select date available')
      return false;
    }
    if(selectedTime === null) {
      toast('Please select time available')
      return false;
    }
    if(firstName === "") {
      toast('Please enter your first name');
      return false;
    }
    if(lastName === "") {
      toast('Please enter your last name');
      return false;
    }
    if(email === "") {
      toast('Please enter your email');
      return false;
    }
    if(phoneNumber === "") {
      toast('Please enter your phone number');
      return false;
    }
    if(paymentMethod === "") {
      toast('Please select your payment method');
      return false;
    }

    setPaymentLoading(true);
    switch(paymentMethod) {
      case 'MADA':
        _paymentMADA(paymentMethod);
        break;
      case 'VISA':
      case 'MASTER':
        _paymentCC(paymentMethod);
        break;
      case 'Apple Pay':
        _paymentApplePay(paymentMethod);
        break;
    }
  }

  function _paymentCC(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var total = parseFloat(selectedData.price) * vat;
    var grandTotal = parseFloat(selectedData.price) + total * parseInt(qty);

    axios.get(api_url+'payment-cc',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `Workshop (CREDIT/DEBIT)`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "Workshop",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/cc/${paymentMethod}`;
        }
      });
    }).catch(error => {
      setPaymentLoading(false);
      alert(error);
    });
  }

  function _paymentMADA(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var total = parseFloat(selectedData.price) * vat;
    var grandTotal = parseFloat(selectedData.price) + total * parseInt(qty);

    axios.get(api_url+'payment-mada',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `Workshop (MADA)`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "Workshop",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/mada/${paymentMethod}`;
        }
      });
    }).catch(error => {
      setPaymentLoading(false);
      alert(error);
    });
  }

  function _paymentApplePay(paymentMethod) {
    var merchantTransactionId = Math.floor(10000000 + Math.random() * 90000000);

    var vat = 0.15;
    var total = parseFloat(selectedData.price) * vat;
    var countTotal = parseFloat(selectedData.price) * parseInt(qty);
    var grandTotal = parseFloat(selectedData.price) + total + countTotal;

    axios.get(api_url+'payment-apple',{
      params: {
        merchantTransactionId: merchantTransactionId.toString(),
        amount: parseFloat(grandTotal).toFixed(2).toString(),
        description: `DIY (APPLE PAY) - ${selectedData.title}`,
        email: email,
        givenName: firstName,
        surname: lastName,
        street1: 'Jeddah Saudi Arabia',
        city: 'Jeddah',
        state: 'Makkah',
        postcode: '22230',
      }
    }).then(res => {
      var params = {
        "type": "DIY",
        "currency": "SAR",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumber": phoneNumber,
        "selectedData": selectedData,
        "selectedDate": selectedDate,
        "selectedTime": selectedTime,
        "qty": qty,
        "total": parseFloat(grandTotal).toFixed(2).toString(),
        "paymentMethod": paymentMethod,
        "createdDate": moment().format('YYYY-MM-DD h:mm:ss'),
        "status": "Pending",
        "checkout": res.data.id,
        "transaction": merchantTransactionId.toString()
      };

      dispatch(saveBooking(params)).then(refKey => {
        if(refKey) {
          window.location.href = `/payment/${merchantTransactionId}/${res.data.id}/${refKey}/apple_pay`;
        }
      });
    }).catch(error => {
      //setPaymentLoading(false);
      alert(error);
    });
  }
}