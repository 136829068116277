import { SPACE_DATA, SPACE_DATA_OUTDOOR } from "../actionTypes";
import { spacesRef, spaceStorage } from "../../firebase";

export const getSpaceIndoor = () => async dispatch => {
    dispatch({
        type: SPACE_DATA,
        payload: [],
        loading: true
    });

    spacesRef.orderByChild('type').equalTo('Indoor').on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: SPACE_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: SPACE_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}

export const getSpacOutdoor = () => async dispatch => {
    dispatch({
        type: SPACE_DATA_OUTDOOR,
        payload: [],
        loading: true
    });

    spacesRef.orderByChild('type').equalTo('Outdoor').on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: SPACE_DATA_OUTDOOR,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: SPACE_DATA_OUTDOOR,
            payload: items.reverse(),
            loading: false
        });
    });
}