import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import adminReducer from "./reducers/adminReducer";
import bookingReducer from "./reducers/bookingReducer";
import canvasReducer from "./reducers/canvasReducer";
import scheduleReducer from "./reducers/scheduleReducer";
import spaceReducer from "./reducers/spaceReducer";
import workshopReducer from "./reducers/workshopReducer";
import bannerReducer from "./reducers/bannerReducer";
import thumbnailReducer from "./reducers/thumbnailReducer";
import studioReducer from "./reducers/studioReducer";

const reducers = combineReducers({
  admin: adminReducer,
  bookings: bookingReducer,
  canvas: canvasReducer,
  schedule: scheduleReducer,
  space: spaceReducer,
  workshop: workshopReducer,
  banner: bannerReducer,
  thumbnail: thumbnailReducer,
  studio: studioReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
export { store };