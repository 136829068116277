import firebase from 'firebase';

const app = firebase.initializeApp({
    apiKey: "AIzaSyCPEprktlkBoaAdncJWw7TUX_2Jl518th4",
    authDomain: "liftksa.firebaseapp.com",
    projectId: "liftksa",
    storageBucket: "liftksa.appspot.com",
    messagingSenderId: "554848823913",
    appId: "1:554848823913:web:65609e6e0ca38befd950f8"
});

export const fbApp = app;
export const oAuth = firebase.auth();
const db = app.database();
const storage = app.storage();

//
// RTDB
//
export const adminRef               = db.ref('admins'); // users
export const workShopsRef           = db.ref('workshops');
export const schedulesRef           = db.ref('schedules');
export const bookingsRef            = db.ref('bookings');
export const canvasRef              = db.ref('canvas');
export const spacesRef              = db.ref('spaces');
export const bannersRef             = db.ref('banners');
export const thumbnailRef           = db.ref('thumbnail');
export const schedRef               = db.ref('schedules');
export const studioRef              = db.ref('studio');
export const thumbnailImgsRef       = db.ref('thumbnail_imgs');
export const subscribersRef         = db.ref('subscribers');

//
// Storage
//
export const workshopStorage        = storage.ref('workshops');
export const spaceStorage           = storage.ref('spaces');
export const bannerStorage           = storage.ref('banners');
export const thumbnailStorage           = storage.ref('thumbnail');
