import { DIY_IMG_DATA, DIY_WORKSHOP_DATA } from "../actionTypes";

const initState = {
    loading: true,
    diyImg: '',
    workshopImg: ''
};

const thumbnailReducer = (state = initState, action) => {
    switch(action.type) {
        case DIY_IMG_DATA:
            return {...state, diyImg: action.payload}
        case DIY_WORKSHOP_DATA:
            return {...state, workshopImg: action.payload}
        default:
            return state;
    }
}

export default thumbnailReducer;