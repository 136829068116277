import React, { useEffect, useState } from 'react';
import {
  Carousel,
  Modal,
  Button
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../../redux/actions/bannerAction';
import { isMobile } from 'react-device-detect';

function Slider(args) {

  const dispatch = useDispatch();
  const state = useSelector(state => state.banner);

  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    dispatch(getBanners());
  }, []);

  function _selectedData(data) {
    setSelectedData(data);
    setDetailsModal(true);
  }

  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/images/space-slider/2.jpg')}
            alt={''}
            style={{height: isMobile ? 300 : 600, width: '100%', objectFit: 'cover'}}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/images/space-slider/3.jpg')}
            alt={''}
            style={{height: isMobile ? 300 : 600, width: '100%', objectFit: 'cover'}}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/images/space-slider/4.jpg')}
            alt={''}
            style={{height: isMobile ? 300 : 600, width: '100%', objectFit: 'cover'}}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/images/space-slider/5.jpg')}
            alt={''}
            style={{height: isMobile ? 300 : 600, width: '100%', objectFit: 'cover'}}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('../../assets/images/space-slider/1.jpg')}
            alt={''}
            style={{height: isMobile ? 300 : 600, width: '100%', objectFit: 'cover'}}
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Slider;