import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingDetails } from '../../redux/actions/bookingAction';
import { ShakeSlow } from 'reshake';
import Footer from '../../components/footer';

function Payment(props) {

  const payment_url = 'https://eu-prod.oppwa.com/';
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  //const payment_url = 'https://oppwa.com/';

  useEffect(() => {
    dispatch(getBookingDetails(props.match.params.key)).then(res => {
      setData(res);
    })
  }, []);

  return (
    <div>
      <Helmet>
        <script src={payment_url+"v1/paymentWidgets.js?checkoutId="+props.match.params.checkout}></script>
      </Helmet>
      <Container style={{marginTop: 50, marginBottom: 50}}>
          <center>

            <ShakeSlow fixed>
                <img src={require('../../assets/images/lift-01.png')} style={{width: 200, objectFit: 'contain', marginBottom: 20}} />
            </ShakeSlow>
          
            <h3>Payment Summary</h3>

            <br/>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{data !== null && data.type === "DIY" ? 'Hours' : 'Title'}</th>
                  <th>{data !== null && data.type === "DIY" ? 'Size' : 'Age Group'}</th>
                  <th>Qty</th>
                  <th>VAT</th>
                  <th>Total</th>
                </tr>
              </thead>
              {_renderData()}
            </Table>
          </center>

          <br/><br/>

          {_renderForm()}

      </Container>
      <Footer />
    </div>
  );

  function _renderForm() {
    switch(props.match.params.type) {
      case 'cc':
        return (
          <form 
              action={"/payment-success/"+props.match.params.transaction_id+"/"+props.match.params.key+"/"+props.match.params.type} 
              class="paymentWidgets" 
              data-brands={props.match.params.paymethod}></form>
        );
      case 'mada':
        return (
          <form 
              action={"/payment-success/"+props.match.params.transaction_id+"/"+props.match.params.key+"/"+props.match.params.type} 
              class="paymentWidgets" 
              data-brands="MADA"></form>
        )
      case 'apple_pay':
        return (
          <form 
              action={"/payment-success/"+props.match.params.transaction_id+"/"+props.match.params.key+"/"+props.match.params.type} 
              class="paymentWidgets" 
              data-brands="APPLEPAY"></form>
        )
    }
  }

  function _renderData() {
    if(data !== null) {
      return (
        <tbody>
          <tr>
            <td>{data.type === "DIY" ? data.selectedData.howManyHours : data.selectedData.title}</td>
            <td>{data.type === "DIY" ? data.selectedData.width+'x'+data.selectedData.height : data.selectedData.ageGroupFrom+' - '+data.selectedData.ageGroupTo}</td>
            <td>{data.qty}</td>
            <td>{'15%'}</td>
            <td>{data.total+' '+data.selectedData.currency}</td>
          </tr>
        </tbody>
      )
    }
  }
}

export default Payment;
