import { AUTH_CHECK, ADMIN_INPUT, ADMIN_DATA } from "../actionTypes";

const initState = {
    loading: true,
    isLoggedIn: false,
    fullname: '',
    username: '',
    password: '',
    name: '',
    type: 'admin' // coordinator
};

const adminReducer = (state = initState, action) => {
    switch(action.type) {
        case AUTH_CHECK:
            return {...state, isLoggedIn: action.payload, loading: action.loading}
        case ADMIN_DATA:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, data: items, loading: action.loading}
        case ADMIN_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
        default:
            return state;
    }
}

export default adminReducer;


