import React, { useEffect, useState } from 'react';
import {Alert, Container, Row, Col, Button} from 'react-bootstrap';
import Footer from '../../components/footer';
import { NavHeader } from '../../components/header';
import { useDispatch } from 'react-redux';
import { getBookingDetails, saveUpdateBooking } from '../../redux/actions/bookingAction';
import axios from 'axios';

const PaymentSuccess = (props) => {

    const dispatch = useDispatch();
    const [emailStatus, setEmailStatus] = useState('Pending');

    useEffect(() => {
        if(emailStatus === 'Pending') {
            dispatch(saveUpdateBooking(props.match.params.key));
            dispatch(getBookingDetails(props.match.params.key)).then(res => {
                axios.post("https://api.emailjs.com/api/v1.0/email/send", {
                    "service_id": "service_83ddj38",
                    "template_id": "template_t39vz6b",
                    "user_id": "HeJvLgT8Wh_McWsuf",
                    "template_params": {
                        "data_title": res.type === "DIY" ? res.selectedData.howManyHours : res.selectedData.title,
                        "data_desc": res.type === "DIY" ? res.selectedData.width+'x'+res.selectedData.height : res.selectedData.ageGroupFrom+' - '+res.selectedData.ageGroupTo,
                        "data_date": res.selectedDate,
                        "data_time": res.selectedTime,
                        "data_qty": res.qty,
                        "data_total": res.total+' SAR',
                        "data_transaction_id": res.transaction,
                        "customer_email": res.email,
                        "from_name": res.type+" Booking Receipt",
                        "to_name": res.firstName
                    }
                }, {
                    headers: {
                    "Content-Type": "application/json"
                    }
                }).then(res => {
                    setEmailStatus(res.data)
                })
            })
        }
    }, []);

    return (
        <Container>
            <NavHeader />
            <Row style={{padding: 20}}>
                <Col md="6" xs="12">
                    <h1>Congratulations</h1>
                    <p>Payment was successful. You will receive an e-mail with attached receipt and confirmation number. Thank you and can't wait to see you!</p>
                    <br/>
                    <Button style={{backgroundColor: '#000', borderWidth: 0}} href='/'>Back to Home</Button>
                </Col>
                <Col md="6" xs="12">
                    <img src={require('../../assets/images/payment.jpg')} style={{width: '100%', objectFit: 'contain', marginBottom: 20, borderRadius: 20}} />
                </Col>
            </Row>
            <Footer />
        </Container>
    );
}

export default PaymentSuccess;