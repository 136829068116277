import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Card, Button } from 'react-bootstrap';
import Slider from './slider';
import { isMobile } from 'react-device-detect';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getThumbnail, getThumbnailImages } from '../../redux/actions/thumbnailAction';
import { NavHeader } from '../../components/header';
import Fade from 'react-reveal/Fade';

export default function Gallery() {
  return (
    <>
        <NavHeader />
        <div style={{marginBottom: 20}}>
          <center>
            <img src={require('../../assets/images/liftGallery.png')} style={{width: isMobile ? 300 : 400, objectFit: 'contain'}} />
            <div className='content-title' style={{width: isMobile ? '100%' : '75%'}}>
              <p style={{fontFamily: 'Exo2Medium', fontSize: 20, lineHeight: 2}}>
                Welcome to the gallery, where people discover new pieces of work by niche, up-and-coming artists, feed their curiosity, get inspiration, start or extend their art collection, and stay up-to-date with our latest art Exhibitions
              </p>
            </div>
          </center>
        </div>
        <Fade bottom>
          <Slider />
        </Fade>
        {isMobile ? _renderMobile() : _renderWeb()}
        <Services />
        <Facilities />
        <FacilityImages />
        <AreYouCollector />
        <Footer />
    </>
  );

  function _renderMobile() {
    return (
      <div style={{marginTop: 50}}>
        <div style={{padding: 20}}>
          <Fade>
            <h1 style={{fontFamily: 'Exo2Medium', letterSpacing: 3, fontSize: 40, marginBottom: 30}}>ABOUT</h1>
          </Fade>
        </div>
        <Fade bottom>
          <img className='aboutImg' src={require('../../assets/images/1.jpg')} style={{width: '100%', height: 250, objectFit: 'cover', marginBottom: 30}}/>
        </Fade>
        <div style={{padding: 20}}>
          <Fade right>
            <p style={{fontFamily: 'Exo2Regular', letterSpacing: 1, fontSize: 16, textAlign: 'justify', lineHeight: 2}}>
              Lift Gallery was established in 2020 in the heart of Riyadh, to become a contemporary art gallery, The Gallery is a platform that brings together the contemporary arts of local and international artists. The Gallery supports artists by providing a creative environment, workshops, and consultations as a part of its commitment to spread the Saudi culture and take a part in developing and enhancing the local artists.
            </p>
          </Fade>
        </div>
      </div>
    )
  }

  function _renderWeb() {
    return(
      <Row style={{marginTop: 100, borderWidth: 1, height: 600}}>
        <Col md="6" style={{padding: 70}}>
          <Fade>
            <h1 style={{fontFamily: 'Exo2Medium', letterSpacing: 3, fontSize: 60, marginBottom: 30}}>ABOUT</h1>
          </Fade>
          <Fade left>
            <p style={{fontFamily: 'Exo2Regular', letterSpacing: 1, fontSize: 20, textAlign: 'justify', lineHeight: 2}}>
              Lift Gallery was established in 2020 in the heart of Riyadh, to become a contemporary art gallery, The Gallery is a platform that brings together the contemporary arts of local and international artists. The Gallery supports artists by providing a creative environment, workshops, and consultations as a part of its commitment to spread the Saudi culture and take a part in developing and enhancing the local artists.
            </p>
          </Fade>
        </Col>
        <Col md="6" style={{textAlign: 'right'}}>
          <Fade>
            <div className='imgContainer'>
              <div className='aboutImgMask'/>
              <img className='aboutImg' src={require('../../assets/images/1.jpg')} style={{width: 500}}/>
            </div>
          </Fade>
        </Col>
      </Row>
    )
  }
}

const Services = () => {
  return (
    <div>
      <div style={{backgroundColor: '#000', padding: 5, textAlign: 'right', marginBottom: isMobile ? 50 : 100}}>
        <Fade>
          <h1 style={{color: '#fff', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 50, letterSpacing: isMobile ? 5 : 20}}>OUR SERVICES</h1>
        </Fade>
      </div>
      <Container>
        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
              <ServicesTxt 
                numbering="01"
                title="Exhibitions"
                desc="Temporary art exhibitions, local and global art fairs, for the public"
                />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
              <ServicesTxt 
                numbering="02"
                title="Private Events & Screenings"
                desc="Private art shows, exhibitions, screenings, space rent for new product launches, etc., for existing & potential customers"
                />
            </Fade>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
              <ServicesTxt 
                numbering="03"
                title="Installation Services"
                desc="Transport and delivery for art pieces, security hanging systems and placement advisory, for customers"
                />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
              <ServicesTxt 
                numbering="04"
                title="Art Advisory"
                desc="Art consultations and matchmaking, for customers"
                />
            </Fade>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade left>
              <ServicesTxt 
                numbering="05"
                title="Pop-ups"
                desc="Unique pop-ups around KSA in key areas, for the public"
                />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12"></Col>
          <Col />
        </Row>
      </Container>
    </div>
  )
}

const ServicesTxt = (props) => {
  return (
    <div style={{marginBottom: isMobile ? 50 : 100}}>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.numbering}</h3>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.title}</h3>
      <p style={{fontFamily: 'Exo2Regular', fontSize: 20}}>{props.desc}</p>
    </div>
  )
}

const Facilities = () => {
  return (
    <Container>
      <div className='facilityBox'>
        <div className='borderLine'/>
        <div>
          <Fade right>
            <h1 style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 70, letterSpacing: isMobile ? 5 : 10, marginBottom: 50}}>OUR FACILITIES</h1>
          </Fade>
          <Fade right>
            <p style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 18 : 25, letterSpacing: isMobile ? 3 : 5, marginBottom: isMobile ? 15 : 25}}>Exibition Area</p>
          </Fade>
          <Fade right>
            <p style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 18 : 25, letterSpacing: isMobile ? 3 : 5, marginBottom: isMobile ? 15 : 25}}>Reception</p>
          </Fade>
          <Fade right>
            <p style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 18 : 25, letterSpacing: isMobile ? 3 : 5, marginBottom: isMobile ? 15 : 25}}>VIP Lounge</p>
          </Fade>
          <Fade right>
            <p style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 18 : 25, letterSpacing: isMobile ? 3 : 5, marginBottom: isMobile ? 15 : 25}}>Storage</p>
          </Fade>
          <Fade right>
            <p style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 18 : 25, letterSpacing: isMobile ? 3 : 5, marginBottom: isMobile ? 15 : 25}}>Cleaning & maintenance</p>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

const FacilityImages = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.thumbnail);
  const [hover, setHover] = useState('');
  const [thumbnails, setThumbnails] = useState([]);
  
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    dispatch(getThumbnail());
  }, []);
  
  function _selectedData(data) {
    setThumbnails([]);
    setSelectedData(data);
    setDetailsModal(true);
    
    dispatch(getThumbnailImages(data._key)).then(res => {
        setThumbnails(res);
    });
  }

  return (
    <div style={{marginTop: 100}}>
      <Row>
        {state.data.map((item, k) => {
          return (
            <Col md="4" xs="12" key={k}>
              {isMobile ? 
                <Fade bottom>
                  <Card style={{marginBottom: 10}} onClick={() => _selectedData(item)}>
                    <Card.Img variant="top" src={item.photo} />
                    <Card.Body>
                      <Card.Title>{item.title}</Card.Title>
                    </Card.Body>
                  </Card> 
                </Fade> : 
                <Fade bottom>
                  <div 
                    style={{width: '100%', height: 400, position: 'relative', cursor: 'pointer'}} 
                    onMouseOver={() => setHover(item.title)} 
                    onMouseOut={() => setHover('')}
                    onClick={() => _selectedData(item)}
                    >
                    <img src={item.photo} alt={item.altText} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                    {hover === item.title && (
                      <div className='hoverContainer'>
                          <strong className='hoverTxt'>{item.title}</strong>
                      </div>
                    )}
                  </div>
                </Fade>
              }
            </Col>
          )
        })}
      </Row>

      {selectedData !== null && (
        <Modal size="lg" show={detailsModal} onHide={() => setDetailsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Exo2Bold'}}>{selectedData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{fontFamily: 'Exo2Regular', color: '#000'}}>{selectedData.desc}</p>
            <br /><br />
            <Row>
              {thumbnails !== 0 && thumbnails.map((file, k) => (
                  <Col md="4" xs="12" key={k}>
                      <img src={file.thumbnails} style={{width: '100%', height: 250, borderRadius: 5, objectFit: 'cover',}}/>
                      <br/><br/>
                  </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              style={{fontFamily: 'Exo2Bold', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}
              variant="secondary" onClick={() => setDetailsModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

const AreYouCollector = () => {
  return (
    <div className='areYouCreator'>
      <Fade>
        <img src={require('../../assets/images/collector.jpg')} style={{width: '100%', height: 600, objectFit: 'cover'}}/>
      </Fade>
      <div className='floatingContent'>
        <Fade bottom>
        <>
        <br/>
        <AreYouText text="ARE"/>
        <AreYouText text="YOU A"/>
        <AreYouText text="COLLECTOR ?"/>
        <div className='lineBreak'/>
        <br/>
        <br/>
        <br/>
        <br/>
        <p style={{fontFamily: 'Exo2Regular', textAlign: 'center', lineHeight: 2.5}}>
          Contact us on our email and send us specific information about you and what are you interested in:
        </p>
        <p  style={{fontFamily: 'Exo2Regular', textAlign: 'center', lineHeight: 2.5}}><a className='link' href="mailto:info@liftksa.com">info@liftksa.com</a></p>
        </>
        </Fade>
      </div>
    </div>
  )
}

const AreYouText = (props) => {
  return (
    <h1 style={{fontFamily: 'Exo2Medium', letterSpacing: 6}}>{props.text}</h1>
  )
}


