import { CANVAS_DATA, CANVAS_DATA_SECOND } from "../actionTypes";

const initState = {
    loading: true,
    first: [],
    second: []
};

const canvasReducer = (state = initState, action) => {
    switch(action.type) {
        case CANVAS_DATA:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, first: items, loading: action.loading}
        case CANVAS_DATA_SECOND:
            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, second: items, loading: action.loading}
        default:
            return state;
    }
}

export default canvasReducer;