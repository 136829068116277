import React, { useEffect, useState } from 'react';
import {
  Carousel,
  Modal,
  Button
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../../redux/actions/bannerAction';
import { isMobile } from 'react-device-detect';

function Slider(args) {

  const dispatch = useDispatch();
  const state = useSelector(state => state.banner);

  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    dispatch(getBanners());
  }, []);

  function _selectedData(data) {
    setSelectedData(data);
    setDetailsModal(true);
  }

  return (
    <>
      <Carousel>
        {state.data.map((item, k) => {
          return(
            <Carousel.Item key={k}>
              <img
                className="d-block w-100"
                src={item.photo}
                alt={k+'-img'}
                style={{height: isMobile ? 300 : 500, width: '100%', objectFit: 'cover'}}
                onClick={() => _selectedData(item)}
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
      {selectedData !== null && (
        <Modal centered size="xl" show={detailsModal} onHide={() => setDetailsModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={selectedData.photo} style={{width: '100%', height: '100%', borderRadius: 5, objectFit: 'cover',}}/>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              style={{fontFamily: 'Exo2Bold', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}
              variant="secondary" onClick={() => setDetailsModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Slider;