import { CANVAS_DATA, CANVAS_DATA_SECOND } from "../actionTypes";
import { canvasRef } from "../../firebase";

export const getCanvasFirst = (params) => async dispatch => {
    dispatch({
        type: CANVAS_DATA,
        payload: [],
        loading: true
    });

    canvasRef.orderByChild('howManyHours').equalTo('1-2 Hours').on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: CANVAS_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: CANVAS_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}

export const getCanvasSecond = (params) => async dispatch => {
    dispatch({
        type: CANVAS_DATA_SECOND,
        payload: [],
        loading: true
    });

    canvasRef.orderByChild('howManyHours').equalTo('3-4 Hours').on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: CANVAS_DATA_SECOND,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: CANVAS_DATA_SECOND,
            payload: items.reverse(),
            loading: false
        });
    });
}