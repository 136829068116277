import { BANNER_DATA, BANNER_INPUT } from "../actionTypes";
import { bannersRef, bannerStorage } from "../../firebase";

export const getBanners = () => async dispatch => {
    dispatch({
        type: BANNER_DATA,
        payload: [],
        loading: true
    });

    bannersRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: BANNER_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: BANNER_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}