import { DIY_IMG_DATA, DIY_WORKSHOP_DATA } from "../actionTypes";
import { studioRef } from "../../firebase";
import moment from "moment";

export const getDiyImg = () => async dispatch => {
    dispatch({
        type: DIY_IMG_DATA,
        payload: '',
        loading: true
    });

    studioRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: DIY_IMG_DATA,
                payload: '',
                loading: false
            });
            return;
        }
        
        dispatch({
            type: DIY_IMG_DATA,
            payload: !snap.val().diyImg ? '' : snap.val().diyImg,
            loading: false
        });
    });
}

export const getWorkshopImg = () => async dispatch => {
    dispatch({
        type: DIY_WORKSHOP_DATA,
        payload: [],
        loading: true
    });

    studioRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: DIY_WORKSHOP_DATA,
                payload: '',
                loading: false
            });
            return;
        }

        dispatch({
            type: DIY_WORKSHOP_DATA,
            payload: !snap.val().workshopImg ? '' : snap.val().workshopImg,
            loading: false
        });
    });
}