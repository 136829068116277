import { BOOKING_DATA, BOOKING_INPUT } from "../actionTypes";
import { bookingsRef, schedRef } from "../../firebase";
import moment from "moment";

export const getBooking = (type) => async dispatch => {
    dispatch({
        type: BOOKING_DATA,
        payload: [],
        loading: true
    });

    bookingsRef.orderByChild('type').equalTo(type).on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: BOOKING_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: BOOKING_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}

export const getAvailableDate = (type, key) => async dispatch => {
    return new Promise((resolve, reject) => {
        schedRef.orderByChild('identifier').equalTo(key).on('value', snap => {
            if(snap.val() !== null) {
                var items = [];
                snap.forEach(child => {
                    items.push(child.val().date_selected);
                })
                resolve(items);
            }else {
                resolve([]);
            }
        });
    });
}

export const getAvailableTiming = (params, type) => async dispatch => {
    return new Promise((resolve, reject) => {
        schedRef.orderByChild('type').equalTo(type).on('value', snap => {
            if(snap.val() !== null) {
                snap.forEach(child => {
                    var check = moment(params).isSame(child.val().date_selected, 'year');
                    if(check) {
                        resolve(child.val().timings);
                    }
                })
            }else {
                resolve([]);
            }
        });
    });
}

export const saveBooking = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        let ref = bookingsRef.push(params);
        resolve(ref.key);
    });
}

export const getBookingDetails = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        bookingsRef.child(key).once('value', snap => {
            resolve(snap.val());
        });
    });
}

export const saveUpdateBooking = (key) => async dispatch => {
    return new Promise((resolve, reject) => {
        bookingsRef.child(key).update({status: 'Paid'});
        resolve("saved");
    });
}

