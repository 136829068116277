import { ADMIN_INPUT, ADMIN_DATA } from "../actionTypes";
import { adminRef, subscribersRef } from '../../firebase';
import { Base64 } from "js-base64";
import moment from "moment";


const salt = "d9090bfdcd31d9759af346596ac070f8f18604e5";
const pepper = "f54e151e0ec88bba906775609efadbd799de2df9";
const auth = window.localStorage.getItem('@liftAdmin');

export const authCheck = () => async dispatch => {
    return new Promise((resolve, reject) => {
        if(auth === null) {
            resolve(false);
        }else {
            resolve(true);
        }
    })
}

export const getUsers = () => async dispatch => {
    dispatch({
        type: ADMIN_DATA,
        payload: [],
        loading: true
    });

    adminRef.on('value', snap => {
        if(snap.val() === null) {
            dispatch({
                type: ADMIN_DATA,
                payload: [],
                loading: false
            });
            return;
        }

        let items = [];
        snap.forEach(child => {
            if(auth !== child.key)
            items.push({
                _key: child.key,
                ...child.val()
            })
        })

        dispatch({
            type: ADMIN_DATA,
            payload: items.reverse(),
            loading: false
        });
    });
}

export const getUser = () => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(auth).once('value' ,snap => {
            resolve(snap.val())
        });
    })
}

export const getSelectedUser = (id) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(id).once('value' ,snap => {
            resolve(snap.val())
        });
    })
}

export const login = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        let password = salt+Base64.encode(state.password)+pepper;
        adminRef.orderByChild('access').equalTo(state.username+'_'+password).once('value' ,snap => {
            if(snap.val() === null) {
                resolve('no_data');
            }else {
                snap.forEach(child => {
                    resolve(child.key);
                });
            }
        });
    })
}

export const logout = () => async dispatch => {
    return new Promise((resolve, reject) => {
        // const promise = account.deleteSessions();
        // promise.then(function (response) {
        //     resolve('success');
        // }, function (error) {
        //     resolve(error.message);
        // });
    })
}

export const addAdmin = (state) => async dispatch => {
    return new Promise((resolve, reject) => {
        let password = salt+Base64.encode('123456')+pepper;
        adminRef.push({
            access: state.username+'_'+password,
            fullname: state.fullname,
            username: state.username,
            password: password,
            type: state.type,
            created_date: moment().format('YYYY-MM-DD h:mm:ss')
        })
        resolve('success');
    })
}

export const editAdmin = (id, state) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(id).update({
            type: state.type,
            fullname: state.fullname,
        })
        resolve('success');
    })
}

export const deleteAdmin = (id) => async dispatch => {
    return new Promise((resolve, reject) => {
        adminRef.child(id).remove();
        resolve('success');
    })
}

export const addSubscribers = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        subscribersRef.push(params)
        resolve('success');
    })
}

export const inputAction = (name, value) => {
    return {
        type: ADMIN_INPUT,
        payload: {
            name: name,
            value: value
        }
    };
}