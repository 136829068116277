import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function Footer() {
  return (
    <Row style={{padding: 20}}>
        <Col style={{textAlign: 'left'}}>
            <p style={{fontFamily: 'Exo2Regular'}}>Lift KSA - All Rights Reserved.</p>
        </Col>
        <Col style={{textAlign: 'right'}}>
            <p style={{fontFamily: 'Exo2Regular'}}>Powered by Web-Us</p>
        </Col>
    </Row>
  );
}
