import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux';
import { Toaster } from 'react-hot-toast';

import Home from './screens/home';

import Gallery from './screens/gallery';

import Studio from './screens/studio';

import Space from './screens/space';

import Payment from './screens/payment';
import PaymentSuccess from './screens/payment/success';

function App() {
  return (
    <Provider store={store}>
      <>
        <Toaster />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route path="/gallery" render={(props) => <Gallery {...props} />} />
            <Route path="/studio" render={(props) => <Studio {...props} />} />
            <Route path="/space" render={(props) => <Space {...props} />} />

            <Route path="/payment/:transaction_id/:checkout/:key/:type/:paymethod" render={(props) => <Payment {...props} />} />
            <Route path="/payment-success/:transaction_id/:key/:type" render={(props) => <PaymentSuccess {...props} />} />

            <Redirect from="/" to="/" />
          </Switch>
        </BrowserRouter>
      </>
    </Provider>
  );
}

export default App;
