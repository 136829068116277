export const AUTH_CHECK = "AUTH_CHECK";
export const ADMIN_DATA = "ADMIN_DATA";
export const ADMIN_INPUT = "ADMIN_INPUT";

export const BOOKING_DATA = "BOOKING_DATA";
export const BOOKING_INPUT = "BOOKING_INPUT";

export const CANVAS_DATA = "CANVAS_DATA";
export const CANVAS_DATA_SECOND = "CANVAS_DATA_SECOND";
export const CANVAS_INPUT = "CANVAS_INPUT";

export const SCHEDULE_DATA = "SCHEDULE_DATA";
export const SCHEDULE_INPUT = "SCHEDULE_INPUT";

export const SPACE_DATA = "SPACE_DATA";
export const SPACE_DATA_OUTDOOR = "SPACE_DATA_OUTDOOR";
export const SPACE_INPUT = "SPACE_INPUT";

export const WORKSHOP_DATA = "WORKSHOP_DATA";
export const WORKSHOP_INPUT = "WORKSHOP_INPUT";

export const BANNER_DATA = "BANNER_DATA";
export const BANNER_INPUT = "BANNER_INPUT";

export const THUMBNAIL_DATA = "THUMBNAIL_DATA";
export const THUMBNAIL_INPUT = "THUMBNAIL_INPUT";

export const DIY_IMG_DATA = "DIY_IMG_DATA";
export const DIY_WORKSHOP_DATA = "DIY_WORKSHOP_DATA";