import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Modal, Form, Button, Spinner } from 'react-bootstrap';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getSpacOutdoor, getSpaceIndoor } from '../../redux/actions/spaceAction';
import { NavHeader } from '../../components/header';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import toast from 'react-hot-toast';
import Fade from 'react-reveal/Fade';
import Slider from './slider';

export default function Space() {
  return (
    <>
      <NavHeader />
      <Container style={{marginBottom: 20}}>
        <center>
          <img src={require('../../assets/images/liftSpace.png')} style={{width: isMobile ? 300 : 400, objectFit: 'contain'}} />
          <div className='content-title' style={{width: isMobile ? '100%' : '75%'}}>
            <p style={{fontFamily: 'Exo2Medium', fontSize: 20, lineHeight: 2}}>
              Welcome to The Space, where artists, designers, and subject matter experts in the creative fields can get their work done in an inspiring environment with both private and common spaces. These members of the creative community can get access to expert talks, events, workshops, engage in networking & collaboration opportunities, hang out with like-minded people and feel part of a community.
            </p>
          </div>
        </center>
        <br/>
      </Container>
      <Fade bottom>
        {/* <center>
          <img src={require('../../assets/images/liftspacebox.png')} style={{width: isMobile ? '100%' : 600}}/>
        </center> */}
        <Slider />
      </Fade>
      <Services />
      <Facilities />
      <Footer />
    </>
  );
}

const Services = () => {

  return (
    <div>
      <div style={{backgroundColor: '#4B785F', padding: 5, textAlign: 'right', marginBottom: isMobile ? 50 : 100}}>
        <h1 style={{color: '#fff', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 50, letterSpacing: isMobile ? 5 : 20}}>OUR SERVICES</h1>
      </div>
      <Container>
        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade bottom>
            <ServicesTxt 
              numbering="01"
              title="Artist Residency"
              desc="Entry exam requirement, 3-6 months duration to support, encourage and promote artists‘ work"
              />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade bottom>
            <ServicesTxt 
              numbering="02"
              title="Memberships"
              desc="Residency (wet & dry), studios (long-term rental offices), and lifters (day or month passes)"
              />
            </Fade>
          </Col>
          <Col />
        </Row>

        <Row>
          <Col />
          <Col md="4" xs="12">
            <Fade bottom>
            <ServicesTxt 
              numbering="03"
              title="Community Events"
              desc="Educational, marketing, and networking events, for members only"
              />
            </Fade>
          </Col>
          <Col />
          <Col md="4" xs="12">
            <Fade bottom>
            <ServicesTxt 
              numbering="04"
              title="Communal Areas"
              desc="Community tables, lounge areas, event space"
              />
            </Fade>
          </Col>
          <Col />
        </Row>
      </Container>
    </div>
  )
}

const ServicesTxt = (props) => {
  return (
    <div style={{marginBottom: isMobile ? 50 : 100}}>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.numbering}</h3>
      <h3 style={{fontFamily: 'Exo2Bold'}}>{props.title}</h3>
      <p style={{fontFamily: 'Exo2Regular', fontSize: 20}}>{props.desc}</p>
    </div>
  )
}

const Facilities = () => {

  const dispatch = useDispatch();
  const state = useSelector(state => state.space);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNUmber] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('indoor')

  useEffect(() => {
    dispatch(getSpacOutdoor());
    dispatch(getSpaceIndoor());
  }, []);

  return (
    <Container style={{marginBottom: 100}}>
      <Fade right>
      <h1 style={{color: '#000', fontFamily: 'Exo2Bold', fontSize: isMobile ? 30 : 50, letterSpacing: isMobile ? 10 : 20, marginBottom: 50, color: '#4B785F'}}>OUR FACILITIES</h1>
      </Fade>
      <Row>
        <Col md="6" xs="12">
          <Fade left>
            <center>
              <h4 style={{
                fontFamily: selectedTab === 'indoor' ? 'Exo2Bold' : 'Exo2Medium',
                cursor: 'pointer'
              }} 
              onClick={() => setSelectedTab('indoor')}
              >Indoor</h4>
              <div style={{width: 200, height: 2, backgroundColor: '#000'}}/>
            </center>
          </Fade>
          <br/><br/>
          {state.indoor.map((item, k) => {
            return (
              <div key={k} className={`spaceIndoorListItems ${selectedTab !== 'indoor' ? 'space-content-blur' : ''}`}>
                <Fade bottom>
                  <div className='frame-img'>
                    <img 
                      className='img-wrapper'
                      src={item.photo}/>
                  </div>
                </Fade>
                <br/><br/>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Title: {item.title}</span> <br/>
                </Fade>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Size: {item.width+'x'+item.height}</span> <br/>
                </Fade>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Type: {item.type}</span> <br/>  
                </Fade>
                
                <br/>
                <Fade right>
                  <div style={{textAlign: 'right'}}>
                    <span 
                      onClick={() => {
                        setSelectedData(item);
                        setDetailsModal(true);
                      }}
                      style={{fontFamily: 'Courier', cursor: 'pointer', textDecoration: 'underline'}}>View</span>
                  </div>
                </Fade>
              </div>
            )
          })}
          {isMobile ? <div style={{marginBottom: 50}}/> : <div />}
        </Col>
        <Col md="6" xs="12">
          <Fade right>
          <center>
            <h4 style={{
              fontFamily: selectedTab === 'outdoor' ? 'Exo2Bold' : 'Exo2Medium',
              cursor: 'pointer'
            }}
            onClick={() => setSelectedTab('outdoor')}
            >Outdoor</h4>
            <div style={{width: 200, height: 2, backgroundColor: '#000'}}/>
          </center>
          </Fade>
          <br/><br/>
          {state.outdoor.map((item, k) => {
            return (
              <div key={k} className={`spaceIndoorListItems ${selectedTab !== 'outdoor' ? 'space-content-blur' : ''}`}>
                <Fade bottom>
                  <div className='frame-img'>
                    <img 
                      className='img-wrapper'
                      src={item.photo}/>
                  </div>
                </Fade>
                <br/><br/>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Title: {item.title}</span> <br/>
                </Fade>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Size: {item.width+'x'+item.height}</span> <br/>
                </Fade>
                <Fade right>
                  <span style={{fontFamily: 'Courier'}}>Type: {item.type}</span> <br/>
                </Fade>
                <br/>
                <Fade right>
                  <div style={{textAlign: 'right'}}>
                    <span 
                      onClick={() => {
                        setSelectedData(item);
                        setDetailsModal(true);
                      }}
                      style={{fontFamily: 'Courier', cursor: 'pointer', textDecoration: 'underline'}}>View</span>
                  </div>
                </Fade>
              </div>
            )
          })}
          {isMobile ? <div style={{marginBottom: 50}}/> : <div />}
        </Col>
      </Row>

      {selectedData !== null && (
        <Modal size='lg' show={detailsModal} onHide={() => setDetailsModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: 'Exo2Bold'}}>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="spaceIndoorListItems">
              <img src={selectedData.photo} style={{width: '100%', height: 500, objectFit: 'contain'}}/>
              {/* <div className='spaceIndoorListItemsContent'>
                <img src={require('../../assets/images/Frame.png')} style={{width: '100%', height: 500, objectFit: 'contain'}}/>
              </div> */}
            </div>
            
            <br/><br/>
            <span style={{fontFamily: 'Courier'}}>Title: {selectedData.title}</span> <br/>
            <span style={{fontFamily: 'Courier'}}>Size: {selectedData.width+'x'+selectedData.height}</span> <br/>
            <span style={{fontFamily: 'Courier'}}>Type: {selectedData.type}</span> <br/>
            <hr />
            <span style={{fontFamily: 'Exo2Bold', float: 'right', fontSize: 20}}>{selectedData.price} SR per day + VAT</span> <br/>
            <br/><br/>
            <h3 style={{fontFamily: 'Exo2Bold', color: '#000'}}>Facilities</h3>
            {selectedData.facilities.map((item, k) => {
              return (
                <div key={k}>
                  <span style={{fontFamily: 'Exo2Regular', fontSize: 14}}>- {item}</span>
                </div>
              )
            })}
            <br/><br/>
            <h3 style={{fontFamily: 'Exo2Bold', color: '#000'}}>Cancellation Policy</h3>
            <span style={{fontFamily: 'Exo2Regular', color: '#000'}}>{selectedData.cancellationPolicy}</span>
            <br/><br/>
            <div style={{marginTop: 30}}>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Available Dates</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='date'
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  placeholder="dd/mm/yyyy"
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Available Timings</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='time'
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>First Name</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Last Name</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Email</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              <Form.Group>
                <Form.Label className='formFontFamilty'>Phone Number</Form.Label>
                <Form.Control 
                  className='diyInput'
                  type='text'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNUmber(e.target.value)}
                  />
              </Form.Group>
              <br/><br/>
              {/* <Form.Group>
                <Form.Label className='formFontFamilty'>Payment Method</Form.Label>
                <Form.Select 
                  className='diyInput'
                  type='select'
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                  <option value="Mada">Mada</option>
                  <option value="Apple Pay">Apple Pay</option>
                  <option value="Visa Master">Visa Master</option>
                </Form.Select>
              </Form.Group> */}
            </div>
          </Modal.Body>
          {loading ? 
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner> : 
            <Modal.Footer>
              <Button 
                style={{fontFamily: 'Exo2Bold', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}
                variant="secondary" onClick={() => setDetailsModal(false)}>Close</Button>
              <Button 
                onClick={() => _book()}
                style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, letterSpacing: 5}}>
                  Inquire
              </Button>
            </Modal.Footer>
          }
        </Modal>
      )}
    </Container>
  )

  function _book() {
    if(firstName === "") {
      toast('Please enter your first name');
      return false;
    }
    if(lastName === "") {
      toast('Please enter your last name');
      return false;
    }
    if(email === "") {
      toast('Please enter your email');
      return false;
    }
    if(phoneNumber === "") {
      toast('Please enter your phone number');
      return false;
    }
    
    setLoading(true);
    axios.post(
      "https://api.emailjs.com/api/v1.0/email/send", {
        "service_id": "service_83ddj38",
        "template_id": "template_51j4rwe",
        "user_id": "HeJvLgT8Wh_McWsuf",
        "template_params": {
          "user_submit": "Space Rental Enquiry",
          "space_type": selectedData.type,
          "space_title": selectedData.title,
          "space_size": selectedData.width+'x'+selectedData.height,
          "space_date": selectedDate,
          "space_time": selectedTime,
          "space_price": selectedData.price,
          "space_first_name": firstName,
          "space_last_name": lastName,
          "space_email": email,
          "space_phone_number": phoneNumber
        }
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(res => {
      if(res.data === "OK") {
        setLoading(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNUmber('');
        setSelectedDate('');
        setSelectedTime('');
        setSelectedData(null);
        setDetailsModal(false);
      }
    })
  }
}
