import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HomeHeader } from '../../components/header';
import { isMobile } from 'react-device-detect';
import { ShakeSlow } from 'reshake';
import Fade from 'react-reveal/Fade';

export default function Home(props) {
  return (
    <>
        <HomeHeader />
        <Container>
            {isMobile ? _renderMobile() : _renderWeb()}
        </Container>
    </>
  );

  function _renderMobile() {
    return (
        <div>
            <center>
                <ShakeSlow fixed>
                    <img src={require('../../assets/images/lift-01.png')} style={{width: 300, objectFit: 'contain'}} />
                </ShakeSlow>
            </center>
            <br/>
            <img src={require('../../assets/images/headSeparate.png')} style={{width: '100%', objectFit: 'contain'}} />
            <br/>
            <br/>
            <Row>
                <Col>
                    <Fade left>
                        <LogoMenuMobile 
                            logoMain={require('../../assets/images/lift-04.png')}
                            logoText={require('../../assets/images/liftGallerySeparate.png')}
                            onClick={() => window.location.href = '/gallery' }
                            />
                    </Fade>
                </Col>
                <Col>
                    <Fade bottom>
                        <LogoMenuMobile 
                            logoMain={require('../../assets/images/lift-03.png')}
                            logoText={require('../../assets/images/liftStudioSeparate.png')}
                            onClick={() => window.location.href = '/studio' }
                            />
                    </Fade>
                </Col>
                <Col>
                    <Fade right>
                        <LogoMenuMobile 
                            logoMain={require('../../assets/images/lift-02.png')}
                            logoText={require('../../assets/images/liftSpaceSeparate.png')}
                            onClick={() => window.location.href = '/space' }
                            />
                    </Fade>
                </Col>
            </Row>
        </div>
    )
  }

  function _renderWeb() {
    return (
        <div className='homePage'>
            <div>
                <ShakeSlow fixed>
                    <img src={require('../../assets/images/lift-01.png')} style={{width: 300, objectFit: 'contain'}} />
                </ShakeSlow>
            </div>
            <div>
                <img src={require('../../assets/images/headSeparate.png')} style={{width: 600, objectFit: 'contain'}} /> <br />
                <br/>
                <Row>
                    <Col md="4">
                        <Fade left>
                            <LogoMenu 
                                logoMain={require('../../assets/images/lift-04.png')}
                                logoText={require('../../assets/images/liftGallerySeparate.png')}
                                onClick={() => window.location.href = '/gallery' }
                                />
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom>
                            <LogoMenu 
                                logoMain={require('../../assets/images/lift-03.png')}
                                logoText={require('../../assets/images/liftStudioSeparate.png')}
                                onClick={() => window.location.href = '/studio' }
                                />
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade right>
                            <LogoMenu 
                                logoMain={require('../../assets/images/lift-02.png')}
                                logoText={require('../../assets/images/liftSpaceSeparate.png')}
                                onClick={() => window.location.href = '/space' }
                                />
                        </Fade>
                    </Col>
                </Row>
            </div>
        </div>
    )
  }
}

const LogoMenu = (props) => {
    return (
        <div className='logoMenu' onClick={props.onClick} style={{cursor:'pointer'}}>
            <div>
                <ShakeSlow>
                    <img src={props.logoMain} style={{width: 80, objectFit: 'contain'}} />
                </ShakeSlow>
            </div>
            <div>
                <img src={props.logoText} style={{width: 90, height: 60, objectFit: 'contain'}} />
            </div>
        </div>
    )
}

const LogoMenuMobile = (props) => {
    return (
        <div className='logoMenu' onClick={props.onClick} style={{cursor:'pointer'}}>
            <div>
                <ShakeSlow>
                    <img src={props.logoMain} style={{width: 80, objectFit: 'contain'}} />
                </ShakeSlow>
                <img src={props.logoText} style={{width: 90, height: 60, objectFit: 'contain'}} />
            </div>
        </div>
    )
}
