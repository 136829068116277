import React, { useEffect, useState } from 'react';
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Modal,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Table
} from 'react-bootstrap';
import { AiOutlineTwitter, AiFillInstagram } from 'react-icons/ai';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import toast from 'react-hot-toast';
import { addSubscribers } from '../redux/actions/adminAction';
import { useDispatch } from 'react-redux';

export const HomeHeader = (args) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [subsModal, setSubsModal] = useState(false);

  useEffect(() => {
    if(window.localStorage.getItem("isSubscribe") == null) {
      setTimeout(() => {
        setSubsModal(true);
      }, 2000);
    }
  }, []);

  return (
    <Navbar>
      <Container>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text style={{fontFamily: 'Exo2Bold', color: '#000'}}>
            <a className='link' href="#" onClick={() => setContactModal(!contactModal)}>
              Contact us
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
      <ContactModal 
        show={contactModal}
        handleClose={() => setContactModal(!contactModal)}
        />
      <SubsModal 
        show={subsModal}
        handleClose={() => setSubsModal(!subsModal)}
        />
    </Navbar>
  );
}

const ContactModal = ({
  show,
  handleClose
}) => {

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [enquiry, setEnquiry] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{margin: 0, padding: 0}}>
        <center>
          <div className='contact-us-modal'>
            <h1 style={{fontFamily: 'Exo2Regular', marginRight: 20}}>CONTACT US</h1> 
            <AiOutlineTwitter size={30} style={{marginRight: 10, cursor: 'pointer'}} onClick={() => _openSocial('https://twitter.com/liftgallery?s=21&t=eRGcIjAONybqGLdqpoRplQ')}/>
            <AiFillInstagram size={30} style={{cursor: 'pointer'}} onClick={() => _openSocial('https://instagram.com/lift.ksa?igshid=YmMyMTA2M2Y=')}/>
          </div>
        </center>
        <Row style={{marginTop: 50, marginBottom: 50}}>
          <Col md="5" xs="12">
            <img 
              src={require('../assets/images/contact.jpg')} 
              style={{
                width: '100%',
                height: 350,
                objectFit: 'cover'
              }}
              />
          </Col>
          <Col md="3" xs="12" style={{zIndex: 100}}>
            <Form.Group>
              <Form.Label className='formFontFamilty'>Full Name</Form.Label>
              <Form.Control 
                className='diyInput'
                type='text'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                />
            </Form.Group>
            <br/>
            <Form.Group>
              <Form.Label className='formFontFamilty'>Email</Form.Label>
              <Form.Control 
                className='diyInput'
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
            <br/>
            <Form.Group>
              <Form.Label className='formFontFamilty'>Enquiry</Form.Label>
              <Form.Control 
                className='diyInput'
                type='text'
                value={enquiry}
                onChange={(e) => setEnquiry(e.target.value)}
                />
            </Form.Group>
            <br/>
            {loading ? <Spinner /> : 
              <Button 
                onClick={() => _submit()}
                style={{fontFamily: 'Exo2Bold', backgroundColor: '#000', borderRadius: 0, borderWidth: 0, float: 'right', letterSpacing: 5}}>Send</Button>
            }
          </Col>
          <Col md="4" xs="12" style={{zIndex: 100}}>
            <div style={{padding: 30}}>
              <a href="https://g.page/lift-exclusive-social-art-club?share" target="_blank">
                <img 
                  src={require('../assets/images/map.png')} 
                  style={{
                    width: '100%',
                    height: 300,
                    objectFit: 'cover'
                  }}
                  />
              </a>
              <div style={{fontFamily: 'Exo2Regular', fontSize: 12, marginTop: 20}}>Al Mathar Ash Shamali, Riyadh 12332</div>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">EMAIL</Col>
                <Col md="9"><a href='mailto:info@liftksa.com'>info@liftksa.com</a></Col>
              </Row>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">PHONE</Col>
                <Col md="9"><a href='tel:+966552858800'>+966 552858800</a></Col>
              </Row>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">MON</Col>
                <Col md="9">WED 11 AM - 11 PM</Col>
              </Row>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">THU</Col>
                <Col md="9">SUN 4 PM - 11 PM</Col>
              </Row>
              {/* <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">FRI</Col>
                <Col md="9">4 PM - 11 PM</Col>
              </Row> */}
              {/* <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">SAT</Col>
                <Col md="9">THU 9 AM - 11 PM</Col>
              </Row>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">SAT</Col>
                <Col md="9">THU 9 AM - 11 PM</Col>
              </Row>
              <Row style={{fontFamily: 'Exo2Regular', fontSize: 12}}>
                <Col md="3">FRI</Col>
                <Col md="9">4 PM - 11 PM</Col>
              </Row> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {isMobile ? null : <div className='contact-float-box'/>}
    </Modal>
  )

  function _submit() {
    if(fullName === "") {
      toast('Please enter your full name');
      return false;
    }
    if(email === "") {
      toast('Please enter your email');
      return false;
    }
    if(enquiry === "") {
      toast('Please enter your enquiry');
      return false;
    }
    
    setLoading(true);
    axios.post(
      "https://api.emailjs.com/api/v1.0/email/send", {
        "service_id": "service_83ddj38",
        "template_id": "template_yz60r6l",
        "user_id": "HeJvLgT8Wh_McWsuf",
        "template_params": {
          "from_name": fullName,
          "message": enquiry,
          "reply_to": email,
          "customer_email": email
        }
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(res => {
      if(res.data === "OK") {
        toast.success('Enquiry successfully submitted');
        setLoading(false);
        setFullName('');
        setEmail('');
        setEnquiry('');

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    })
  }

  function _openSocial(url) {
    window.open(url, '_blank')
  }
}

const SubsModal = ({
  show,
  handleClose
}) => {

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{margin: 0, padding: 0}}>
        <Row>
          <Col md={6}>
            <div style={{width: '100%', height: '100%', backgroundColor: "#2E3130", padding: 20}}>

              <div style={{color: "#fff", textAlign: "right", width: "100%", marginBottom: 100}}>
                <h1 style={{fontSize: 60, fontFamily: "Exo2Bold"}}>LIFT YOURSELF</h1>
                <h2 style={{fontFamily: "Exo2Regular"}}>and be the first one to</h2>
                <h2 style={{fontFamily: "Exo2Regular"}}>know</h2>
              </div>

              <div style={{textAlign: "center", marginBottom: 100}}>
                <h4 style={{fontFamily: "Exo2Regular", color: "#fff"}}>Sign up for Lift emails to be the first to see inspiring content, news, and exclusive offers</h4>
              </div>

              <div>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <Form.Control
                          type="text"
                          placeholder='Name'
                          style={{
                            backgroundColor: "transparent",
                            color: "white",
                            fontFamily: "Exo2Bold",
                            borderWidth: 0
                          }}
                          onChange={(e) => setName(e.target.value)}
                          />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Form.Control
                          type="email"
                          placeholder='Email Address'
                          style={{
                            backgroundColor: "transparent",
                            color: "white",
                            fontFamily: "Exo2Bold",
                            borderWidth: 0
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          />
                      </td>
                      <td style={{textAlign: "center"}}>
                        <Button 
                          onClick={() => _onSubmit()}
                          variant="light" style={{
                          backgroundColor: "transparent",
                          color: "white",
                          fontFamily: "Exo2Bold",
                          borderWidth: 0,
                        }}>
                          Subscribe
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

            </div>
          </Col>
          <Col md={6}>
            <img src={require('../assets/images/news-img.png')} style={{width: '100%', objectFit: 'contain'}} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )

  function _onSubmit() {
    if(name == "") {
      toast.error('Please enter your full name');
      return false;
    }

    if(email == "") {
      toast.error('Please enter your email address');
      return false;
    }

    if(!isValidEmail(email)) {
      toast.error('Please enter your valid email address');
      return false;
    }

    

    dispatch(addSubscribers({
      name: name,
      email: email
    })).then(res => {
      if(res === "success") {
        window.localStorage.setItem("isSubscribe", email);
        toast.success('You are successfully subscribed');
        handleClose();
      }
    })
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
}

export const NavHeader = (args) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{marginBottom: 30}}>
      <Navbar expand="lg" fixed="top" className='headerNavBar'>
        <Container>
          <Navbar.Brand href="/">
          <img src={require('../assets/images/lift-01.png')} style={{width: 50, objectFit: 'contain'}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/studio">Studio</Nav.Link>
              <Nav.Link href="/space">Space</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br/><br/><br/>
    </div>
  );
}