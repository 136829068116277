import { WORKSHOP_DATA, WORKSHOP_INPUT } from "../actionTypes";

const initState = {
    loading: true,
    data: [],

    thumbnail: [],
    thumbnailUrl: '',
    backgroundImage: [],
    backgroundImageUrl: '',

    title: '',
    description: '',
    ageGroupFrom: '',
    ageGroupTo: '',
    duration: '',
    instructor: '',
    price: '',
    currency: 'SAR'
};

const teamsReducer = (state = initState, action) => {
    switch(action.type) {
        case WORKSHOP_DATA:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, data: items, loading: action.loading}
        case WORKSHOP_INPUT:
            return {...state, [action.payload.name]: action.payload.value}
        default:
            return state;
    }
}

export default teamsReducer;