import { SPACE_DATA, SPACE_DATA_OUTDOOR } from "../actionTypes";

const initState = {
    loading: true,
    indoor: [],
    outdoor: [],
};

const spaceReducer = (state = initState, action) => {
    switch(action.type) {
        case SPACE_DATA:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, indoor: items, loading: action.loading}
        case SPACE_DATA_OUTDOOR:

            var items = [];
            if(action.payload !== null) {
                for(var i=0; i<action.payload.length; i++) {
                    items.push(action.payload[i]);
                }
            }

            return {...state, outdoor: items, loading: action.loading}
        default:
            return state;
    }
}

export default spaceReducer;